import React from "react";
import { useHistory } from "react-router-dom";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import logo from "./assets/logo/logo.png";
import appUrl from "./app/constants/appUrl";
import imageIcon from "../src/assets/icons/user-icon.png";

export const AppTopbar = (props) => {
  const history = useHistory();
  const { user } = props;

  const confirm = () => {
    confirmDialog({
      message: "Are you sure you want to Logout?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept,
      reject,
      acceptClassName: "custom-bg color-white",
      rejectClassName: "custom-white",
    });
  };

  const accept = () => {
   localStorage.clear();
    history.push("./");
  };

  const reject = () => {
    return;
  };

  return (
    <>
      <ConfirmDialog />

      <div className="layout-topbar">
        <div className="layout-topbar-logo">
          <img src={logo} alt="logo" />
          <span className="custom-color">Virtual Card</span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div className="layout-topbar-ellipsis flex align-items-center">
            <button
              type="button"
              className="p-link  layout-menu-button layout-topbar-button"
              onClick={props.onToggleMenuClick}
            >
              <i className="pi pi-bars" />
            </button>
            {props.currentRouteLabel && (
              <div className="layout-topbar-heading">
                {props.currentRouteLabel}
              </div>
            )}
          </div>

          <div className="layout-topbar-user">
            <div className="dropdown">
              <button
                className="btn btn-secondary dropdown-toggle d-flex align-items-center"
                style={{ gap: "10px" }}
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="profile-container">
                  <img
                    src={
                      user?.profileImageUrl
                        ? `${appUrl.baseUrl}${user.profileImageUrl}`
                        : imageIcon
                    }
                    alt="Profile"
                    className="profile-image"
                  />
                  <div className="status-dot"></div>
                </div>
                Hello,&nbsp; {user ? user?.name : "Admin"} &nbsp;
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                {user?.accessControls &&
                  user.accessControls.length > 0 &&
                  user.accessControls[0].organization && (
                    <li>
                      <span style={{ padding: "15px" }}>
                        <b>Organization Details</b>
                      </span>
                      <div className="dropdown-item d-flex align-items-center">
                        <img
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "10px",
                          }}
                          src={
                            user.accessControls[0].organization.logoUrl
                              ? `${appUrl.baseUrl}${user.accessControls[0].organization.logoUrl}`
                              : imageIcon
                          }
                          alt="Organization Logo"
                          className="profile-image"
                        />
                        {user.accessControls[0].organization.name}
                      </div>
                    </li>
                  )}
                {user?.userName && (
                  <li>
                    <span style={{ padding: "15px" }}>
                      <b> Admin Details</b>
                    </span>
                    <div className="dropdown-item">
                      <i
                        className="pi pi-phone"
                        style={{ marginRight: "10px" }}
                      ></i>
                      {user.userName}
                    </div>
                  </li>
                )}
                {user?.email && (
                  <li>
                    <div className="dropdown-item">
                      <i
                        className="pi pi-envelope"
                        style={{ marginRight: "10px" }}
                      ></i>
                      {user.email}
                    </div>
                  </li>
                )}

                <li>
                  <a className="dropdown-item" onClick={confirm}>
                    <i
                      className="pi pi-sign-out"
                      style={{ marginRight: "10px" }}
                    ></i>
                    Logout
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
