import { Button } from "primereact/button";
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import * as Yup from "yup";
import { Dropdown } from "primereact/dropdown";
import appUrl from "../../../../constants/appUrl";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import moment from "moment";
import { FileUpload } from "primereact/fileupload";

const AddEditPeoples = ({ onHide, editable, setRes, rowData, setDialog }) => {
  const { user } = useSelector((state) => state.auth);
  const [gateList, setGateList] = useState();
  const [loading, setLoading] = useState(false);
  const [groupList, setGroupList] = useState();
  const [deptList, setDeptList] = useState();
  const [imageBase64, setImageBase64] = useState("");
  const [fileName, setFileName] = useState("");

  const handleImageUpload = (event) => {
    const file = event.files[0];
    setFileName(file.name);
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64Image = reader.result.split(",")[1];
      setImageBase64(base64Image);
      formik.setFieldValue("profileImageBase64", base64Image);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const validationSchema = Yup.object({
    phoneNumber: Yup.string()
      .required("Mobile number is required")
      .matches(/^[3-9]\d{9}$/, "Invalid mobile number"),
    expiry: Yup.string().required("Required"),
    name: Yup.string()
      .required("Required")
      .max(30, "Name must be 30 characters"),
    pin: editable
      ? Yup.string().notRequired()
      : Yup.string()
          .min(4, "Pin must be 4 characters if provided")
          .max(30, "Pin must be 30 characters if provided"),
    gateIds: Yup.array().min(1, "GateNumber is required").required("Required"),
    groupId: Yup.string()
      .required("Required")
      .max(30, "Title must be 30 characters"),
    cnicNumber: Yup.string()
      .required("Required")
      .min(13, "CNIC must be 13 characters")
      .max(13, "CNIC must be 13 characters"),
    profileImageBase64: Yup.string().required("Logo image is required"),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      phoneNumber: "",
      name: "",
      pin: "",
      newPin: "",
      gateIds: [],
      groupId: "",
      departmentId: "",
      email: "",
      cnicNumber: "",
      address: "",
      expiry: "",
      customerId: "",
      referenceUserId: "",
      profileImageBase64: "",
    },

    onSubmit: async (data) => {
      setLoading(true);
      try {
        if (!data.departmentId) {
          data.departmentId = null;
        }
        if (data.expiry) {
          data.expiry = moment(data.expiry).format("YYYY-MM-DD");
        }
        data.phoneNumber = `+92${data.phoneNumber}`;


        let response;
        if (editable) {
          data.id = rowData.id;
          const updatedData = {
            ...data,
            id: rowData.id,
          };
          response = await axios.put(
            `${appUrl.baseUrl}/api/People/update`,
            updatedData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            }
          );
        } else {
          response = await axios.post(
            `${appUrl.baseUrl}/api/People/add`,
            data,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            }
          );
        }

        if (response.data && response.data.responseCode === 200) {
          // Success case
          setRes(response);
          const message = editable
            ? "People Updated Successfully"
            : response.data.responseDescription.trim().toLowerCase() ===
              "success"
            ? "People Added Successfully"
            : response.data.responseDescription;

          toast.success(message);

          setDialog(false);
        } else {
          // Error case
          if (response.data && response.data.responseDescription) {
            toast.error(response.data.responseDescription);
          } else {
            toast.error("Failed to perform operation");
          }
        }
      } catch (error) {
        console.error("Error occurred:", error);
        toast.error("Failed to perform operation");
      } finally {
        setLoading(false);
      }
    },
  });

  const GetGates = async () => {
    try {
      const userId =JSON.parse(localStorage.getItem("user")) 
      const response = await fetch(
        `${appUrl.baseUrl}/api/Gates/list?loggedInUserId=${userId?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setGateList(data?.data);
    } catch (error) {}
  };

  const GetGroups = async () => {
    try {
      const userId =JSON.parse(localStorage.getItem("user")) 
      const response = await fetch(
        `${appUrl.baseUrl}/api/Groups/list?loggedInUserId=${userId?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setGroupList(data?.data);
    } catch (error) {}
  };

  const GetDepartments = async () => {
    try {
      const userId =JSON.parse(localStorage.getItem("user")) 
      const response = await fetch(
        `${appUrl.baseUrl}/api/Departments/list?loggedInUserId=${userId?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setDeptList(data?.data);
    } catch (error) {}
  };

  const handleInitialImage = async (imageUrl) => {
    try {
      const response = await axios.get(imageUrl, {
        responseType: "arraybuffer",
      });

      const base64 = Buffer.from(response.data, "binary").toString("base64");

      formik.setFieldValue("profileImageBase64", base64);
      const extractedFileName = imageUrl.split("/").pop();
      setFileName(extractedFileName);
    } catch (error) {
      console.error("Error fetching and converting image:", error);
    }
  };

  useEffect(() => {
    if (editable && rowData) {
      const gateIds =
        rowData?.personAccessControls?.map((control) => control.gateId) || [];
      formik.setValues({
        name: rowData?.name || "",
        phoneNumber: rowData?.phoneNumber || "",
        pin: rowData?.pin || "",
        gateIds: gateIds,
        groupId: rowData?.groupId || "",
        departmentId: rowData?.departmentId || "",
        address: rowData?.address || "",
        email: rowData?.email || "",
        cnicNumber: rowData?.cnicNumber || "",
        expiry: rowData?.personAccessControls?.[0]?.expiry
          ? moment(rowData.personAccessControls[0].expiry).toDate()
          : "",
        customerId: rowData?.customerId || "",
        referenceUserId: rowData?.referenceUserId || "",
      });
      if (rowData.profileImageUrl) {
        handleInitialImage(`${appUrl.baseUrl}${rowData.profileImageUrl}`);
      }
    }
  }, [editable, rowData]);

  useEffect(() => {
    GetGates();
    GetGroups();
    GetDepartments();
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid formgrid grid">
          <div className="field  md:col-6">
            <label htmlFor="" style={{ fontWeight: "bold" }}>
              Name <span className="Staric-Custom"> *</span>
            </label>
            <InputText
              keyfilter={/^[a-zA-Z\s]*$/}
              maxLength={30}
              id="name"
              name="name"
              type="text"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="error">{formik.errors.name}</div>
            ) : null}
          </div>
          {editable ? null : (
            <div className="field  md:col-6">
              <label htmlFor="" style={{ fontWeight: "bold" }}>
                Mobile Number <span className="Staric-Custom"> *</span>
              </label>
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon">+92</span>
                <InputText
                  id="phoneNumber"
                  name="phoneNumber"
                  type="text"
                  maxLength={10}
                  value={formik.values.phoneNumber}
                  onChange={(e) => {
                    let input = e.target.value.replace(/[^\d]/g, "");
                    if (input.startsWith("0")) {
                      input = input.substring(1);
                    }
                    formik.setFieldValue("phoneNumber", input);
                  }}
                />
              </div>
              {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                <div className="error">{formik.errors.phoneNumber}</div>
              ) : null}
            </div>
          )}

          <div className="field  md:col-6">
            <label className="mb-2" style={{ fontWeight: "bold" }}>
              Gate Number<span style={{ color: "red" }}>*</span>
            </label>

            <MultiSelect
              value={formik.values.gateIds}
              options={gateList}
              optionLabel="number"
              optionValue="id"
              placeholder="Select Gate Numbers"
              name="gateIds"
              onChange={(e) => formik.setFieldValue("gateIds", e.value)}
              display="chip"
            />
            {formik.touched.gateIds && formik.errors.gateIds ? (
              <div className="error">{formik.errors.gateIds}</div>
            ) : null}
          </div>

          <div className="field  md:col-6">
            <label className="mb-2" style={{ fontWeight: "bold" }}>
              Group<span style={{ color: "red" }}>*</span>
            </label>
            <Dropdown
              value={formik.values.groupId}
              optionLabel="name"
              name="groupId"
              options={groupList}
              optionValue="id"
              placeholder="Select"
              onChange={(e) => formik.setFieldValue("groupId", e.value)}
              // onChange={formik.handleChange}
            />
            {formik.touched.groupId && formik.errors.groupId ? (
              <div className="error">{formik.errors.groupId}</div>
            ) : null}
          </div>

          <div className="field  md:col-6">
            <label className="mb-2" style={{ fontWeight: "bold" }}>
              Gate Expiration Date<span style={{ color: "red" }}>*</span>
            </label>
            <Calendar
              id="expiry"
              value={formik.values.expiry}
              onChange={formik.handleChange}
              showIcon
              dateFormat="yy-mm-dd"
              placeholder="Select Date"
              minDate={new Date()}
            />
            {formik.touched.expiry && formik.errors.expiry ? (
              <div className="error">{formik.errors.expiry}</div>
            ) : null}
          </div>
          <div className="field  md:col-6">
            <label htmlFor="" style={{ fontWeight: "bold" }}>
              CNIC <span className="Staric-Custom"> *</span>
            </label>
            <InputText
              id="cnicNumber"
              name="cnicNumber"
              type="text"
              value={formik.values.cnicNumber}
              maxLength={13}
              onChange={(e) =>
                formik.setFieldValue(
                  "cnicNumber",
                  e.target.value.replace(/[^0-9]/g, "")
                )
              }
            />
            {formik.touched.cnicNumber && formik.errors.cnicNumber ? (
              <div className="error">{formik.errors.cnicNumber}</div>
            ) : null}
          </div>
          <div className="field  md:col-6">
            <label htmlFor="" style={{ fontWeight: "bold" }}>
              Address{" "}
            </label>
            <InputText
              maxLength={99}
              id="address"
              name="address"
              type="text"
              value={formik.values.address}
              onChange={formik.handleChange}
            />
          </div>
          <div className="field  md:col-6">
            <label htmlFor="" style={{ fontWeight: "bold" }}>
              Email{" "}
            </label>
            <InputText
              id="email"
              name="email"
              type="text"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
          </div>
          <div className="field  md:col-6">
            <label className="mb-2" style={{ fontWeight: "bold" }}>
              Department
            </label>
            <Dropdown
              value={formik.values.departmentId}
              optionLabel="name"
              name="departmentId"
              options={deptList}
              optionValue="id"
              placeholder="Select"
              onChange={(e) => formik.setFieldValue("departmentId", e.value)}
            />
          </div>

          <div className="field  md:col-6">
            <label htmlFor="" style={{ fontWeight: "bold" }}>
              Customer Id
            </label>
            <InputText
              maxLength={6}
              id="customerId"
              name="customerId"
              value={formik.values.customerId}
              onChange={formik.handleChange}
            />
          </div>

          <div className="field  md:col-6">
            <label htmlFor="" style={{ fontWeight: "bold" }}>
              Reference User Id (Roll no)
            </label>
            <InputText
              maxLength={6}
              id="referenceUserId"
              name="referenceUserId"
              value={formik.values.referenceUserId}
              onChange={formik.handleChange}
            />
          </div>

          <div className="field  md:col-6">
            <label htmlFor="profileImageBase64" style={{ fontWeight: "bold" }}>
              Upload Logo <span className="Staric-Custom"> *</span>
            </label>
            <FileUpload
              mode="basic"
              name="profileImageBase64"
              accept="image/*"
              maxFileSize={1000000}
              onSelect={handleImageUpload}
              auto
            />
            {fileName && (
              <p
                style={{
                  marginBottom: "-8px",
                  fontSize: "12px",
                  marginTop: "5px",
                }}
              >
                Selected file:
              </p>
            )}

            {formik.values.profileImageBase64 && (
              <div style={{ marginTop: "10px" }}>
                <img
                  src={`data:image/jpeg;base64,${formik.values.profileImageBase64}`}
                  alt="Profile"
                  style={{
                    width: "100%",
                    height: "100px",
                    objectFit: "cover",
                    border: "1px solid #ced4da",
                    borderRadius: "12px",
                  }}
                />
              </div>
            )}
            {formik.touched.profileImageBase64 &&
            formik.errors.profileImageBase64 ? (
              <div className="error">{formik.errors.profileImageBase64}</div>
            ) : null}
          </div>

          <div className="col-12 md:col-8"></div>
          <div className="col-12 md:col-2">
            <div className="flex justify-content-center">
              <Button
                className="custom-white w100 "
                label="Cancel"
                type="button"
                onClick={onHide}
              />
            </div>
          </div>
          <div className="col-12 md:col-2">
            <div className="flex justify-content-center">
              <Button
                className="custom-btn w100"
                label={editable ? "Update" : "Add "}
                type="submit"
                disabled={loading}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddEditPeoples;
