import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { formatTo12Hour } from "../../../components/helper";
import appUrl from "../../../../constants/appUrl";

const LoginForm = () => {

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [otpDialogVisible, setOtpDialogVisible] = useState(false);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [receivedOtp, setReceivedOtp] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);
  const [otpExpiry, setOtpExpiry] = useState("");
  const [otpExpired, setOtpExpired] = useState(false);
  const user = useSelector((state) => state.auth.user);

  // Formik setup for login
  const formik = useFormik({
    initialValues: {
      mobileNumber: "",
      password: "",
    },
    validationSchema: Yup.object({
      mobileNumber: Yup.string()
        .required("Mobile number is required")
        .matches(/^[3-9]\d{9}$/, "Invalid mobile number"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const payload = {
          mobileNumber: `+92${values.mobileNumber}`,
          secret: values.password,
        };

        const response = await axios.post(
          `${appUrl.baseUrl}/api/Account/login`,
          payload
        );

        if (response.data.responseCode === 200) {
          setMobileNumber(`+92${values.mobileNumber}`);
          const receivedOtp = response.data.data.otp.toString();

          setOtp(receivedOtp.split("").map((digit) => digit));
          setReceivedOtp(receivedOtp);

          const expiryTime = response.data.data.otpExpiry;
          setOtpExpiry(expiryTime);

          setOtpDialogVisible(true);
          setOtpExpired(false);
        } else {
          const errorMessage =
            response.data.responseDescription || "Failed to perform operation";
          toast.error(errorMessage);
        }
      } catch (error) {
        console.error("Error occurred:", error);
        const errorMessage =
          error.response?.data?.responseDescription ||
          "Failed to perform operation";
        toast.error(errorMessage);
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (otpExpiry) {
        const currentTime = new Date().getTime();
        const expiryTime = new Date(otpExpiry).getTime();

        if (currentTime > expiryTime) {
          setOtpExpired(true);
          clearInterval(intervalId);
        }
      }
    }, 60000);

    return () => clearInterval(intervalId);
  }, [otpExpiry]);

  const handleChange = (e, index) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);

    // Automatically focus next input field
    if (e.target.value && index < 3) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  const handleOtpSubmit = async () => {
    try {
      const otpPayload = {
        userName: mobileNumber,
        otp: otp.join(""),
      };

      const otpResponse = await axios.post(
        `${appUrl.baseUrl}/api/Account/verify-otp`,
        otpPayload
      );

      if (otpResponse.data.responseCode === 200) {
        const userRole = otpResponse?.data.data.user.role;
        const user = otpResponse?.data.data.user;

        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", otpResponse.data?.data?.accessToken);
        // console.log("Current User:", user);
        // console.log("User ID:", user?.id);
        if (user.mustChangePassword) {
          setShowPasswordDialog(true);
          return;
        }

        if (userRole === "SuperAdministrator") {
          history.push("/organizations");
        } else if (userRole === "Administrator") {
          history.push("/");
        }

        setTimeout(() => {
          toast.success(
            `${
              otpResponse.data.responseDescription ||
              "OTP Verified successfully!"
            } OTP Verified! Logged in successfully!`
          );
        }, 100);
      } else if (otpResponse.data.responseCode === 400) {
        toast.error(otpResponse.data.responseDescription || "Invalid OTP!");
      } else {
        console.error(
          "OTP Verification Error:",
          otpResponse.data.responseDescription
        );
      }
    } catch (error) {
      console.error("OTP Verification error:", error);
      toast.error("Internal server Error");
    }
  };

  const changePasswordValidationSchema = Yup.object({
    password: Yup.string()
      .required("Password is required")
      .matches(
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}/,
        "Password must be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, 1 number, and 1 symbol."
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const formikPasswordChange = useFormik({
    validationSchema: changePasswordValidationSchema,
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    onSubmit: async (values) => {

      try {
        const userId =JSON.parse(localStorage.getItem("user")) 
        const payload = {
          id: userId?.id,
          password: values.password,
        };
        // console.log(payload, "idd");

        const response = await axios.put(
          `${appUrl.baseUrl}/api/Admin/update/password`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );

        // Check if the response status indicates success
      if (response.status === 200) { // or response.data.success depending on your API response
        toast.success("Password updated successfully");
        
        // Navigate based on user role after a successful update
        const user = JSON.parse(localStorage.getItem("user")); // Ensure you get the current user data
        if (user.role === "SuperAdministrator") {
          history.push("/organizations");
        } else if (user.role === "Administrator") {
          history.push("/");
        }

        // Close the dialog after success
        setShowPasswordDialog(false);
      } else {
        // Handle cases where the response doesn't indicate success (if necessary)
        toast.error("Unexpected error. Please try again.");
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.responseDescription ||
        "Password update failed. Please try again.";
      toast.error(errorMessage);
    }
  },
});

  const handleRedirect = () => {
    history.push("/register");
  };

  return (
    <>
      {showPasswordDialog && (
        <Dialog
          visible={showPasswordDialog}
          header="Change Your Password"
          onHide={() => setShowPasswordDialog(false)}
          style={{ width: "350px" }}
          footer={
            <Button
              type="submit"
              label="Change Password"
              onClick={formikPasswordChange.handleSubmit}
              className="custom-btn mt-3"
            />
          }
        >
          <form
            className="p-fluid formgrid grid auth-login"
            onSubmit={formikPasswordChange.handleSubmit}
          >
            <div className="field md:col-12 mb-0">
              <label>Password</label>
              <Password
                id="password"
                name="password"
                placeholder="Enter password"
                value={formikPasswordChange.values.password}
                onChange={formikPasswordChange.handleChange}
                toggleMask
                feedback={false}
              />
              {formikPasswordChange.touched.password &&
              formikPasswordChange.errors.password ? (
                <div className="error">
                  {formikPasswordChange.errors.password}
                </div>
              ) : null}
            </div>
            <div className="field md:col-12 mb-0">
              <label>Confirm Password</label>
              <Password
                id="confirmPassword"
                name="confirmPassword"
                placeholder="Confirm your password"
                value={formikPasswordChange.values.confirmPassword}
                onChange={formikPasswordChange.handleChange}
                toggleMask
                feedback={false}
              />
              {formikPasswordChange.touched.confirmPassword &&
              formikPasswordChange.errors.confirmPassword ? (
                <div className="error">
                  {formikPasswordChange.errors.confirmPassword}
                </div>
              ) : null}
            </div>
          </form>
        </Dialog>
      )}

      <Dialog
        header="Enter OTP"
        visible={otpDialogVisible}
        style={{ width: "350px" }}
        onHide={() => setOtpDialogVisible(false)}
      >
        <div className="field">
          <label>
            An OTP has been sent to your number. Please enter it here
          </label>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
              marginTop: "20px",
            }}
          >
            {otp.map((digit, index) => (
              <InputText
                key={index}
                id={`otp-input-${index}`}
                value={digit}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                maxLength={1}
                style={{ width: "50px", textAlign: "center" }}
                inputMode="numeric"
              />
            ))}
          </div>
        </div>
        {otpExpired && (
          <p
            className="mx-auto text-center"
            style={{ color: "red", fontWeight: "bold" }}
          >
            OTP has expired. Please try again.
          </p>
        )}
        {otpExpiry && (
          <p
            className="mx-auto text-center"
            style={{ color: "green", fontWeight: "bold" }}
          >
            OTP will expire at: {formatTo12Hour(otpExpiry)}
          </p>
        )}
        <div
          className="field"
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            label="Verify OTP"
            className="custom-btn p-button"
            onClick={handleOtpSubmit}
            disabled={otp.some((digit) => digit === "")}
          />
        </div>
      </Dialog>
      <div className="p-fluid formgrid grid auth-login">
        <div className="field md:col-4"></div>
        <div
          className="field md:col-4"
          style={{
            background: "white",
            padding: "40px",
            borderRadius: "10px",
            backdropFilter: "blur(12px)",
          }}
        >
          <div className="text-center">
            <img
              src={"assets/layout/images/Zindigi.svg"}
              alt="logo"
              style={{ width: "150px" }}
            />
          </div>
          <br />
          <h4 className="auth-welcome mt-3 text-center">
            Log in to your account
          </h4>
          <p className="text-center">
            Welcome back! Please enter your details.
          </p>
          <form
            style={{ width: "100%" }}
            className="grid p-fluid justify-content-left align-items-left mt-5"
            onSubmit={formik.handleSubmit}
          >
            <div className="field md:col-12 mb-0">
              <label>
                Mobile number <span className="asteric">&nbsp;*</span>
              </label>
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon">+92</span>
                <InputText
                  className="auth-welcome"
                  placeholder="3000000000"
                  id="mobileNumber"
                  name="mobileNumber"
                  maxLength={10}
                  value={formik.values.mobileNumber}
                  onChange={(e) => {
                    let input = e.target.value.replace(/[^\d]/g, "");
                    if (input.startsWith("0")) {
                      input = input.substring(1);
                    }
                    formik.setFieldValue("mobileNumber", input);
                  }}
                />
              </div>
              {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
                <div className="error">{formik.errors.mobileNumber}</div>
              ) : null}
            </div>

            <div className="field md:col-12 mb-0">
              <label>
                Password <span className="asteric">&nbsp;*</span>
              </label>
              <Password
                className="auth-welcome"
                placeholder="Enter password"
                type="text"
                id="password"
                name="password"
                maxLength={30}
                value={formik.values.password}
                toggleMask
                onChange={formik.handleChange}
                feedback={false}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="error">{formik.errors.password}</div>
              ) : null}
            </div>

            <div className="field md:col-12 mb-0">
              <Button
                loading={loading}
                type="submit"
                className="custom-btn mt-3"
                label="Log In"
                disabled={loading}
              />
            </div>
            <p className="mx-auto" style={{ textAlign: "center" }}>
              If you don't have an account?{" "}
              <a onClick={handleRedirect}>Signup here</a>
            </p>
          </form>
        </div>
        <div className="field md:col-4"></div>
      </div>
    </>
  );
};

export default LoginForm;
