import React, { useState, useRef, useEffect, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import AddEditPeoples from "../components/add_edit_peoples";
import { Dialog } from "primereact/dialog";
import appUrl from "../../../../constants/appUrl";
import axios from "axios";
import { confirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import Loader from "../../../components/loader";
import {
  formatDate,
  renderTextWithTooltip,
  formatTo12Hour,
  emailsRenderTextWithTooltip,
} from "../../../components/helper";
import editIcon from "../../../../../assets/icons/bx_edit.png";
import { Tag } from "primereact/tag";
import * as XLSX from "xlsx";
import fetchWith401Handling from "../../../../services/fetchWith401Handling";
import deleteIcon from "../../../../../assets/icons/trash.png";

const People = () => {
  const [dialog, setDialog] = useState();
  const [peopleList, setPeopleList] = useState();
  const [res, setRes] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowselect, setRowselect] = useState(null);
  const [editable, setEditable] = useState(false);
  const [expandedRows, setExpandedRows] = useState(null);

  const dt = useRef(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const GetPeoplesList = async () => {
    try {
      setLoading(true);
      const response = await fetchWith401Handling(
        `${appUrl.baseUrl}/api/People/all`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setPeopleList(data?.data);
      setLoading(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onHide = () => {
    setDialog(false);
  };
  const editAction = (rowData) => {
    setEditable(true);
    setRowselect(rowData);
    setDialog(true);
  };
  const actionTemplate = (rowData) => (
    <div>
      <Button
        className="custom-btn-edit"
        onClick={() => {
          editAction(rowData);
        }}
      >
        <img
          src={editIcon}
          alt="Edit"
          style={{ width: "20px", height: "20px" }}
        />
      </Button>
      <Button
        className="custom-btn-delete"
        onClick={() => {
          confirm(rowData);
        }}
        >
        <img
          src={deleteIcon}
          alt="Delete"
          style={{ width: "20px", height: "20px" }}
        />
      </Button>
    </div>
  );
  const confirm = (rowData) => {
    confirmDialog({
      header: <div className="custom-header">Delete People</div>,
      icon: "pi pi-info-circle",
      message: <strong>Are you sure you want to delete this People?</strong>,
      accept: () => accept(rowData),
      reject: () => reject(rowData),
      acceptClassName: "custom-btn-red",
      rejectClassName: "custom-white-red",
      className: "center-buttons no-close-button",
    });
  };
  const accept = async (rowData) => {
    try {
      const response = await axios.delete(
        `${appUrl.baseUrl}/api/People/delete/${rowData?.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      setRes(response.data);
      toast.success("Deleted Successfully");
    } catch (error) {}
  };
  const reject = (rowData) => {
    return;
  };

  const exportCSV = () => {
    const exportData = peopleList.map((item) => ({
      Name: item.name,
      "Mobile Number": item.phoneNumber,
      CNIC: item.cnicNumber,
      Email: item.email,
      Address: item.address,
      Group: item.groupName,
      "Modified Date": formatTo12Hour(item.modifiedDate),
    }));

    // Create a new workbook and a worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(exportData);

    // Define column widths (all set to 120 pixels)
    worksheet["!cols"] = [
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
    ];

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "People Data");

    // Write the workbook to file
    XLSX.writeFile(workbook, "people_data.xlsx");
  };

  useEffect(() => {
    GetPeoplesList();
  }, [res]);

  const columnsData = [
    {
      field: "name",
      header: "Name",
      body: (rowData) => renderTextWithTooltip(rowData, "name", "bottom"),
    },
    {
      field: "phoneNumber",
      header: "Mobile Number",
    },
    {
      field: "cnicNumber",
      header: "CNIC",
    },
    {
      field: "email",
      header: "Email",
      body: (rowData) => emailsRenderTextWithTooltip(rowData, "email", "bottom"),
    },
    {
      field: "address",
      header: "Address",
      body: (rowData) => renderTextWithTooltip(rowData, "address", "bottom"),
    },
    {
      field: "groupName",
      header: "Group",
      body: (rowData) => renderTextWithTooltip(rowData, "groupName", "bottom"),
    },
    {
      field: "modifiedDate",
      header: "Date",
      body: (rowData) => formatDate(rowData.modifiedDate),
    },
    {
      field: "profileImageUrl",
      header: "Profile Image",
      body: (rowData) => (
        <img
          src={`${appUrl.baseUrl}${rowData.profileImageUrl}`}
          alt="Profile Image"
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
      ),
    },
    {
      header: "Action",
      body: actionTemplate,
    },
  ];

  const renderAccessControls = useCallback((rowData) => {
    return (
      <DataTable value={rowData?.personAccessControls} scrollable>
        <Column field="gate.number" header="Gate Number" />
        <Column field="gate.description" header="Gate Description" />
        <Column
          field="supportsOfflineMode"
          header="Gate Status"
          body={(rowData) =>
            rowData?.gate?.supportsOfflineMode ? (
              <Tag severity="warning" value="Offline" />
            ) : (
              <Tag severity="success" value="Online" />
            )
          }
        />
        <Column
          field="modifiedDate"
          header="Date"
          body={(data) => formatDate(data.modifiedDate)}
        />
        <Column
          field="expiry"
          header="Expiry"
          body={(data) => formatDate(data.expiry)}
        />
      </DataTable>
    );
  }, []); 
  
  const rowExpansionTemplate = (data) => {
    return <div>{renderAccessControls(data)}</div>;
  };

  return (
    <>
      <Dialog
        visible={dialog}
        onHide={onHide}
        header={editable ? "Edit People" : "Add People"}
        style={{ width: "50vw" }}
      >
        <AddEditPeoples
          dialog={dialog}
          editable={editable}
          rowData={rowselect}
          setDialog={setDialog}
          onHide={onHide}
          setRes={setRes}
        />
      </Dialog>

      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Search"
            />
          </span>
        </div>

        <div className="col-12 md:col-10 flex justify-content-end filter-responsive">
          <Button
            className="custom-btn export-excel"
            label="Export to Excel"
            icon="pi pi-window-maximize"
            onClick={exportCSV}
          />

          <Button
            className="custom-btn  ml-3"
            label="Add New"
            icon="pi pi-plus"
            onClick={() => {
              setEditable(false);
              setDialog(true);
            }}
          />
        </div>
      </div>

      <div className="card">
        {loading && <Loader />}

        <DataTable
          value={peopleList}
          scrollable
          scrollHeight="450px"
          filters={filters}
          paginator
          rows={10}
          ref={dt}
          globalFilterFields={[
            "name",
            "groupName",
            "mobileNumber",
            "gateNumber",
          ]}
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          className="custom-data-table"
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
        >
          <Column expander style={{ width: "3em" }} />
          {columnsData.map((data, index) => (
            <Column
              key={index}
              field={data.field}
              header={data.header}
              body={data.body}
            />
          ))}
        </DataTable>
      </div>
    </>
  );
};

export default People;
